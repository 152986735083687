@tailwind base;
@tailwind components;
@tailwind utilities;


.alert-success {
    background-color: #d4edda;
    color: #155724;
    border-color: #c3e6cb;
}

.alert-danger {
    background-color: #f8d7da;
    color: #721c24;
    border-color: #f5c6cb;
}

.horizontal-slider {
  margin: 10px 0;
  height: 25px;
  display: flex;
  align-items: center;
}

.thumb {
  height: 30px;
  line-height: 30px;
  width: 30px;
  text-align: center;
  background-color: #000;
  color: #fff;
  border-radius: 50%;
  cursor: grab;
  top: -5px;
}

.track {
  top: 0;
  bottom: 0;
  background: #ddd;
  border-radius: 999px;
}

.track-1 {
  background: #13293D;
}

.track-2 {
  background: #13293D;
}
